import List from "@mui/material/List";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import Stack from "@mui/material/Stack";

import { _API } from "../../App";
import { useEffect, useState, useMemo, useCallback } from "react";
import UsedRow, { action as usedRowAction } from "./UsedRow";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";
import Ad from "../ad/Ad";
import { useFetcher, useNavigate, useLoaderData, useParams } from "react-router-dom";
import { useOnScroll } from "../../hooks/useOnScroll";
import { Outlet } from "react-router-dom";
import { useBoxWidth } from "../../hooks/useBoxWidth";
import TranslatedTypography from "../Language/TranslatedTypography";
const page_load_limit = 8;

const GroupedRows = ({ group, width, forceReloadOfData }) => {
    const navigate = useNavigate();

    return useMemo(
        () => (
            <>
                {group.map((product, index) => {
                    return (
                        <UsedRow
                            width={width}
                            key={index}
                            product={{ ...product, path: `/thumbnails/${product?.path}` }}
                            onClick={() => navigate(`/used/${product.url}`)}
                            forceReloadOfData={forceReloadOfData}
                        />
                    );
                })}
            </>
        ),
        [group, width, navigate, forceReloadOfData]
    );
};

export default function Products(props) {
    const initialData = useLoaderData();
    const [products, setProducts] = useState(initialData.products?.body);
    const [groupedProducts, setGroupedProducts] = useState([]);
    const [category, setCategory] = useState("all");
    const fetcher = useFetcher();
    const scrolling = useOnScroll();
    const params = useParams();

    const [infiniteScroll, setInfiniteScroll] = useState(true);
    const [ovverrideScrollFetcher, setOvverrideScrollFetcher] = useState(false);

    const { width: productRowWidth, ref: productWidthRef } = useBoxWidth(groupedProducts);

    /*        {
            image: "/public/ads/pulverlacksutrustning.png",
            title: "Skriv upp sig på vårt nyhetsbrev",
            desc: `<p class="ql-align-center"><strong class="ql-size-large">Erbjudande, lageruppdateringar, nyheter</strong></p>`,
            link: "/used/newsletter",
            customButtonText: "Nyhetsbrev",
            {
                image: "/public/ads/manualConveyor.jpg",
                title: "Komplett pulverlacksanläggning med conveyors",
                desc: '<p class="ql-align-center"><span class="ql-size-large">14 x 9m med conveyors<br><span class="ql-size-huge"><strong style="color: red;">675 000kr ex moms</strong></span><br><span style="color:#212121;"><strong>12 000kr/mån</strong> med leasing från Nordic finance</span></span><br>Gäller till 2024-05-04</p>',
                link: "/page/5552-leasing-sverige",
            },
        },*/

    const [ads] = useState([
        {
            image: "/public/ads/heldors.jpg",
            title: "",
            desc: `<p class="ql-align-center"><span class="ql-size-large"><strong>HYBE </strong>kan nu med glädje erbjuda hela sortimentet av kvalitetsugnar från</p><p class="ql-align-center"> </span></p>`,
            link: "/page/heldors",
        },
        {
            image: "/public/ads/pulverlacksutrustning.png",
            title: "Pulverlackutrustning",
            desc: `<p class="ql-align-center"><strong class="ql-size-large" style="color: black;">Ugnar - Lackboxar - Pulversprutor - Tvättar - Conveyors - Kompressorer</strong></p>`,
            link: "/page/pulverlackutrustning",
        },
    ]);

    const loadNext = useCallback(
        (skip, limit, category) => {
            const query = `?skip=${skip}&limit=${limit}&category=${category}`;
            fetcher.load(query);
        },
        [fetcher]
    );

    useEffect(() => {
        if (Object.keys(params).length !== 0) return setOvverrideScrollFetcher(true);
        setOvverrideScrollFetcher(false);
    }, [params]);

    useEffect(() => {
        if (!products) setGroupedProducts(null);
        if (products) {
            const newGrouped = products.reduce((agg, row, index) => {
                if (index % 4 === 0) agg.push([row]);
                else agg[agg.length - 1].push(row);
                return agg;
            }, []);
            setGroupedProducts([...newGrouped]);
        }
    }, [products]);

    useEffect(() => {
        if (!fetcher.data?.products?.body || fetcher.state === "loading") return;

        const newProducts = fetcher.data.products?.body;
        const skip = fetcher.data.skip;
        const limit = fetcher.data.limit;
        if (!Array.isArray(newProducts)) return;
        if (newProducts.length === 0) setInfiniteScroll(false);
        setProducts((prevProducts) => {
            if (skip && limit && Number(skip) + Number(limit) === prevProducts?.length) return prevProducts;
            if (prevProducts) return [...prevProducts, ...newProducts];
            return [...newProducts];
        });
    }, [fetcher.data, fetcher.state]);

    useEffect(() => {
        if (ovverrideScrollFetcher || !infiniteScroll || fetcher.state === "loading") return;

        const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight;
        const currentScroll = window.scrollY;

        const scrollEnded = currentScroll + 500 >= scrollableHeight;

        if (scrollEnded || products === undefined || products === null) {
            loadNext(products?.length ?? 0, page_load_limit, category);
        }
    }, [scrolling, products, category, fetcher?.state, infiniteScroll, ovverrideScrollFetcher, loadNext]);

    const reset = useCallback(() => {
        setProducts(null);
        setInfiniteScroll(true);
    }, []);

    const handleCategoryChange = (_, newCategory) => {
        if (newCategory === null || newCategory === category) return;
        setCategory(newCategory);
        reset();
    };

    const theme = useTheme();
    const matches = !useMediaQuery(theme.breakpoints.up("lg"));

    const AdById = ({ id, ...props }) => {
        if (id === undefined || ads[id] === undefined) return <></>;

        return <Ad {...ads[id]} {...props}></Ad>;
    };

    return (
        <>
            <Box sx={{ width: "100%", overflow: "hidden" } /**Temporary hidden*/}>
                {groupedProducts ? (
                    <List>
                        {groupedProducts?.map((groupOfProduct, index) => {
                            const adSpot = index % 2 === 0;
                            const adNumber = index / 2;

                            return (
                                <Grid container spacing={3} wrap="nowrap">
                                    <Grid item xs>
                                        {index === 0 && (
                                            <>
                                                <Box ref={productWidthRef}></Box>
                                                <Stack sx={{ marginBottom: 2 }}>
                                                    <ToggleButtonGroup
                                                        sx={{
                                                            marginTop: 1,
                                                            backgroundColor: "white.main",
                                                            boxShadow: 3,
                                                        }}
                                                        color="primary"
                                                        exclusive
                                                        name={"category"}
                                                        value={category}
                                                        onChange={handleCategoryChange}
                                                        fullWidth
                                                    >
                                                        <ToggleButton value="all">
                                                            <TranslatedTypography>Alla</TranslatedTypography>
                                                        </ToggleButton>
                                                        <ToggleButton value="oven">
                                                            <TranslatedTypography>Ugnar</TranslatedTypography>
                                                        </ToggleButton>
                                                        <ToggleButton value="machine">
                                                            <TranslatedTypography>Maskiner</TranslatedTypography>
                                                        </ToggleButton>
                                                    </ToggleButtonGroup>
                                                </Stack>
                                            </>
                                        )}
                                        <GroupedRows
                                            group={groupOfProduct}
                                            width={productRowWidth}
                                            forceReloadOfData={reset}
                                        />
                                        {adSpot && matches && <AdById id={adNumber} sx={{ marginBottom: 2 }} />}
                                    </Grid>
                                    {!matches && (
                                        <Grid item xs={4}>
                                            {adSpot && <AdById id={adNumber} />}
                                        </Grid>
                                    )}
                                </Grid>
                            );
                        })}
                        {fetcher.state === "loading" && (
                            <GroupedRows group={[...Array(page_load_limit)]} width={productRowWidth} />
                        )}
                    </List>
                ) : (
                    <CircularProgress></CircularProgress>
                )}
            </Box>

            <Outlet></Outlet>
        </>
    );
}

export const loader = async (context) => {
    const url = new URL(context.request.url);
    const skip = url.searchParams.get("skip") || 0;
    const limit = url.searchParams.get("limit") || page_load_limit;
    const category = url.searchParams.get("category") || "all";

    return { skip: skip, limit: limit, products: await _API.used.getAll({ skip, limit, category }) };
};

export const action = async (input) => {
    const formData = await input.request.formData();
    const intent = formData.get("intent");
    if (intent === "remove_product") return await usedRowAction({ ...input, formData });
    throw new Error("Invalid intent");
};
